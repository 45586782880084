import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheterTirageAvecPrix from "../../../../composants/boutique/BoutonAcheterTirageAvecPrix"
import Image from "../../../../composants/image/Image"
import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import OpenSeaDragonViewer from "../../../../composants/image/OpenSeaDragonViewer"

import {
  obtenirPage,
  obtenirImages,
  obtenirImage,
} from "../../../../js/client-es/utils"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageMegalaxyZoom: allPagesCompilationYaml(
      filter: { code: { eq: "megalaxyZoomEN" } }
    ) {
      ...FragmentPageYaml
    }
    imageAVendre: allImagesCompilationYaml(
      filter: { code: { in: ["megalaxyZoom"] } }
    ) {
      nodes {
        ...FragmentDonneesImages
      }
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: { code: { in: ["voieLacteeMegalaxyZoom"] } }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["astroPixelProcessorMegalaxyZoom"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["megalaxyZoomAnnotee"] } }
    ) {
      ...FragmentImagePortrait
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: { code: { in: ["moiSousLesEtoiles"] } }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function MegalaxyZoom(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageMegalaxyZoom")
  const images = obtenirImages(
    resultatsRequete,
    "imageAVendre",
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPortrait",
    "imagesPortraitPetit"
  )

  const image = {
    xmlns: "http://schemas.microsoft.com/deepzoom/2009",
    Format: "jpg",
    Overlap: "1",
    Size: { Height: "16831", Width: "13655" },
    TileSize: "256",
    Url: "/ressources/deep-zoom/megalaxyzoom/tuiles/",
  }

  const composantImageInteractive = <OpenSeaDragonViewer image={image} />

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <h2 className="section-titre">Foreword</h2>
        <p>
          This image of <strong>230 million pixels</strong> presents a detailed
          view of the galactic center. This very bright region of the night sky
          stretches from the Scorpius constellation to the Corona Australis
          constellation. Realizing this image was {""}
          <strong>impossible from France</strong> because the galactic center is
          too low on the southern horizon. The acquisitions were therefore made
          in the southern hemisphere, under the most beautiful skies in the
          world when the center galactic was at its zenith: 2 trips to Chile
          (2016 and 2018) and 1 trip to Namibia (2019).
        </p>
        <p>
          There, during the southern autumn and winter, {""}
          <strong>the galactic center is at its zenith</strong> and the stars
          shine so bright that they light up the night. It is a light from the
          past. Darkness is therefore not complete and once our eyes are used to
          it, it reigns like a « little Moon » atmosphere: it is possible to
          move around without a headlamp. I invite you to discover this
          extraordinary region of sky, rich in golden stars, dark and colorful
          nebulae.
        </p>
        <div id="galerie-image-interactive-conteneur-achat-tirage-avec-prix">
          <BoutonAcheterTirageAvecPrix
            image={obtenirImage(images, "megalaxyZoom")}
            langue={page.langue}
          />
        </div>
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">The image in numbers</h2>
        <ul>
          <li>
            Total exposure time: <strong>38 hours and 40 minutes</strong>.
          </li>
          <li>
            Number of stacked images: <strong>2320</strong>.
          </li>
          <li>
            Final dimensions: 13655 * 16831 pixels (
            <strong>229.8 mega pixels)</strong>.
          </li>
          <li>
            37 tiles were put together to create the mosaic, each representing a
            total exposure of about 1 hour (or more when I forgot to cut the
            intervalometer).
          </li>
          <li>
            Weight of the final .tif file (compressed): <strong>1.98 GB</strong>
            .
          </li>
          <li>
            Total calculation time: I don’t really remember anymore, but each
            calculation of the mosaic took about 10 hours. My PC may have ran
            for more than 150 hours in total just to compute the image :)
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "moiSousLesEtoiles")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Annotated version</h2>
        <p>
          The 3 constellations have been drawn and 46 objects identified on this
          annotated image:
        </p>
        <Image
          image={obtenirImage(images, "megalaxyZoomAnnotee")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Situation</h2>
        <p>
          The image covers the regions of the constellations of Sagittarius,
          Scorpius and Corona Australis which lie around the bulb galactic, an
          extremely rich region in celestial objects.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeMegalaxyZoom")}
          langue={page.langue}
        />
      </section>
      <section className="section-conteneur">
        <h2 className="section-titre">Hardware and software</h2>
        <p>
          Acquisitions were made with a Canon EOS 6D Astrodon modded set to ISO
          800 and a Canon EF 135 mm F2 lens set to F2.5. The mounts were a Vixen
          GPD2 (Chile 2016) and then a small Skywatcher Star Adventurer (Chile
          2018, Namibia 2019). These mounts not being automatable, the framing
          was made manually using the live view with therefore a lot of
          overlaps… hence the big number of tiles. Preprocessing and assembly of
          the mosaic were made with the Astro Pixel Processor software and the
          final processing was done in Photoshop.
        </p>
        <Image
          image={obtenirImage(images, "astroPixelProcessorMegalaxyZoom")}
          langue={page.langue}
          afficherTitre={false}
        />
      </section>
    </LayoutGalerieImageInteractive>
  )
}
